import React from "react";
import "./Footer.scss";
import partnerLogo from "../../Assets/images/partner-logos.png";
import { Link } from "react-router-dom";
import Whatsapp from "../../components/Whatsapp/Whatsapp";

const Footer = () => {
  return (
    <div className="footerSection">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-left mt-4  ">
            <span>
              <h3 className="text-light  mt-2" style={{display:'inline-block'}}>SchoolSmart</h3>
              <p className="text-light heading5" style={{ fontSize: "20px" }}>
                by LinkIT
              </p>
            </span>
            <div className="row">
              <div className="col-md-3 text-light">
                <ul>
                  <i className="fas fa-home text-light"></i><Link to="/" className="text-light decor" > Home</Link>
                </ul>
                <ul>
                  <i className="fab fa-product-hunt "></i><Link to="/product" className="text-light decor"> Product</Link>
                </ul>
                <ul>
                  <i class="fab fa-servicestack"></i> <Link to="/consultancy" className="text-light decor"> Services</Link>
                </ul>
                <ul>
                  <i class="fas fa-building"></i> Company
                </ul>
                <ul>
                  <i class="fas fa-address-book"></i> <Link to="/contactUs" className="text-light decor"> Contact</Link>
                </ul>
              </div>
              <div className="col-md-4 text-light">
                <ul>
                  {" "}
                  <i class="fas fa-question-circle"></i> <Link to="/technicalSupport" className="text-light decor"> Support</Link>
                </ul>
                <ul>
                  <i class="fas fa-church"></i> Community
                </ul>
                <ul>
                  <i class="fas fa-laptop-code"></i> Developer
                </ul>
                <ul>
                  <i class="fas fa-search-location"></i> Marketplace
                </ul>
              </div>
              <div className="col-md-3 text-light">
                <ul>
                  <i class="fab fa-twitter"></i> Twitter
                </ul>
                <ul>
                  <i class="fab fa-facebook"></i> Facebook
                </ul>
                <ul>
                  <i class="fab fa-instagram"></i>Instagram
                </ul>
                <ul>
                  <i class="fab fa-youtube"></i> Youtube
                </ul>
              </div>

              <div className="text-light mt-2">
                <Link to="/schedule" className="text-light">
                  {" "}
                  <h6>
                    REQUEST A DEMO <i class="far fa-share-square"></i>
                  </h6>
                </Link>
                <h4 className="text-center">www.Schoolsmart360.com</h4>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-left mt-4 mb-2  text-light">
            <div className="rightSectionFooter container">
              <h3>GET INSIGHTS FROM OUR BLOG</h3>
              <div className="row mb-1">
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "18px" }} className="mt-2">
                  <Link to="/academicSmart" className="text-light decor">Academic</Link>
                  </p>
                </div>
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "18px" }} className="mt-2">
                  <Link to="/administrationSmart" className="text-light decor">Administration</Link>
                  </p>
                </div>
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "18px" }} className="mt-2">
                  <Link to="/studentSmart" className="text-light decor">Admission</Link>
                  </p>
                </div>
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "18px" }} className="mt-2">
                  <Link to="/communicationSmart" className="text-light decor">Communication</Link>
                  </p>
                </div>
              </div>
              <div className="row" style={{ textAlign: "center" }}>
                <div className="col-md-3">
                  <p style={{ fontSize: "18px" }} className="mt-2">
                  <Link to="/installation" className="text-light decor"> Insatallation</Link>
                  </p>
                </div>
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "18px" }} className="mt-2">
                  <Link to="/integration" className="text-light decor"> Integration</Link>
                  </p>
                </div>
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "18px" }} className="mt-2">
                  <Link to="/migration" className="text-light decor"> Migration</Link>
                  </p>
                </div>
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "18px" }} className="mt-2">
                  <Link to="/training" className="text-light decor"> Training</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 container">
              <h4>PROUD AFFILIATE PARTNERS OF</h4>
              <div className="row">

                <img className="mt-2" src={partnerLogo} alt="logo" />

              </div>
            </div>
            <div className="whatsAppDiv">
            <Whatsapp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
