import React from "react";
import ProductSectionRight from "../../components/ProductSesction/ProductSectionRight";
import ProductSectionLeft from "../../components/ProductSesction/ProductSectionLeft";
import studentAdmission from "../../Assets/screens/student-s.png";
import academic from "../../Assets/screens/academic-s.png";
import administration from "../../Assets/screens/administration-s.png";
import communication from "../../Assets/screens/communication-s.png";
import hr from "../../Assets/screens/HR-s.png";
import library from "../../Assets/screens/library-s.png";
import pastoral from "../../Assets/screens/pastoral-s.png";
import timetable from "../../Assets/screens/timetable-s.png";
import reporting from "../../Assets/screens/reporting-system.png";

import ReadMoreReact from 'read-more-react';
import "./Products.scss";
const Products = (props) => {


  return (
    <div>
      <div className="container">
        <div className="row">
          <u style={{ color: "#00a1df" }}>
            <h1 style={{ color: "black" }} className="mt-4 mb-4 font">
              Products
            </h1>
          </u>
          <ProductSectionRight
            backgroundColor={"#fff2cc"}
            src={studentAdmission}
            heading={"STUDENT "}
            smallHeading={"An Efficient Paperless Admissions Process"}
            link={"/studentSmart"}
            text={<p><ReadMoreReact text={"The paperless form sits within your school’s own website so parents can access it quickly and easily. From here, each parent can complete your enquiry and registration forms online, as well as it can be done in campus."}
            min={65}
            ideal={100}
            max={100}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />
          <ProductSectionLeft
            backgroundColor={"#deebf7"}
            src={hr}
            heading={"HR "}
            smallHeading={"Alleviate the HR burden through self administration"}
            //text={<p>By and large, staff the executives has been an activity in passing reports and structures to and fro. Information put away in divergent areas, slow interchanges among HR and educators and other staff individuals and a ton of tedious administrator, <span id="dots">...</span><span id="more"> pursuing and hanging tight – for everybody concerned. HRSmart's cloud-based self-administration work changes the progression of data. It gives instructors and staff liability regarding their own data, a prompt, initially perspective on everything from occasions booked and time off wiped out through to contract terms and impending preparing openings.</span></p>  }
            link={"/hrSmart"}
            text={<p><ReadMoreReact text={"By and large, staff the executives has been an activity in passing reports and structures to and fro. Information put away in divergent areas, slow interchanges among HR and educators and other staff individuals and a ton of tedious administrator pursuing and hanging tight – for everybody concerned. HRSmart's cloud-based self-administration work changes the progression of data. It gives instructors and staff liability regarding their own data, a prompt, initially perspective on everything from occasions booked and time off wiped out through to contract terms and impending preparing openings."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
            
            

         
         
         
          />
          <ProductSectionRight
            backgroundColor={"#f0eeda"}
            src={academic}
            heading={"ACADEMIC "}
            smallHeading={
              "A role-based efficient fully integrated academic management tool"
            }
            link={"/academicSmart"}
            text={<p><ReadMoreReact text={"Making sure you have everything that you need to complete your various daily tasks is essential. Safely storing data that syncs across your MIS system, AcademicSmart is designed so you can guide and keep track of your teachers and the subjects, students and classes they teach. This includes tools to help you create sets and synchronise timetables across the school."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />
          <ProductSectionLeft
            backgroundColor={"#f4cdff"}
            src={administration}
            heading={"ADMINISTRATION "}
            smallHeading={
              "A cloud-based solution for all your administrative requirements"
            }
            link={"/administrationSmart"}
            text={<p><ReadMoreReact text={"At the core of a successfully run school is the administrative tasks to support, our suite of modules includes everything from school admissions, HR and census right through to data protection. Allowing your administration office to take complete control over your schools’ operations."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />
          <ProductSectionRight
            backgroundColor={"#fffed4"}
            src={communication}
            heading={"COMMUNICATION "}
            smallHeading={"An Effective tool for Improvement of School Communication Management"}
            link={"/communicationSmart"}
            text={<p><ReadMoreReact text={"CommunicationSmart is an essential tool for; understanding roles and assignments; planning and carrying out learning activities; coordinating approaches with students; providing information to teachers on student progress and behaviors; and building a positive relationship with students, teachers and parents."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />
          <ProductSectionLeft
            backgroundColor={"#fff2cc"}
            src={studentAdmission}
            heading={"FINANCE "}
            smallHeading={"Accounting, consolidation and business intelligence features offer you the ability to generate predefined and custom reports, profiled by user."}
            link={"/financeSmart"}
            text={<p><ReadMoreReact text={"Every schools’ finance team requires comprehensive systems to support the practice of effectively managing money and budgets to support the schools’ long-term financial strategy. Our modules and FinanceSmartTM solution support these important financial tasks giving your finance team the best tools to align with the school’s strategic plan."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />

          <ProductSectionRight
            backgroundColor={"#e2f0d9"}
            src={library}
            heading={"Library "}
            smallHeading={"Enables librarians and users to save time on daunting tasks and enhances efficiency"}
            link={"/librarySmart"}
            text={<p><ReadMoreReact text={"LibrarySmart offers a smart application for your school to systematically manage library operations, an opportunity to know how well-maintained the record of issued books and collection is. The  librarian and the administration department can access various reports to implement new improvements."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />
          <ProductSectionLeft
            backgroundColor={"#f2f2f2"}
            src={pastoral}
            heading={"PASTORAL "}
            smallHeading={"A dedicated Pastoral Care Module to track the behaviour of students including academic achievement, attendance, and detention."}
            link={"/pastoralSmart"}
            text={<p><ReadMoreReact text={"We have carefully developed our pastoral care module to ensure that you can support your staff and student wellbeing, and that it continues to evolve to suit the requirements of our community.It is integrated throughout the organisation of your school, our modules support schools to meet the wellbeing needs of students from activities, disciplines to reward and conduct."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />
          <ProductSectionRight
            backgroundColor={"#d8fce9"}
            src={timetable}
            heading={"TIMETABLE "}
            smallHeading={"A fully automated web-based timetable management tool that saves time and  effort, create an error-free timetable, update students and teachers by instant notification."}
            link={"/timetableSmart"}
            text={<p><ReadMoreReact text={"The ideal tool for scheduling and organising your school’s timetables. You can easily create an unlimited number of timetables, each of which are customised to your school’s specifications and style. Then you can add staff meetings, year schedules and teacher availability, all whilst highlighting any potential clashes or other issues."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />
          <ProductSectionLeft
            backgroundColor={"#fff2cc"}
            src={reporting}
            heading={"REPORT "}
            smallHeading={"It offers a wide-array of data reports across all its modules as intuitive visualizations."}
            link={"/reportSmart"}
            text={<p><ReadMoreReact text={"Multiple staff members can write reports for an individual student in the same subject, as it is a fully integrated and completely web-based tool. These reports can then be published in bulk to the relevant Student and Parent Portals."}
            min={100}
            ideal={150}
            max={200}
            readMoreText= {<strong>click here to read more</strong>}/></p>  }
          />
        </div>
      </div>
    </div>
  );
};
export default Products;
