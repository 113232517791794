import { Link } from "react-router-dom";
import career from "../../Assets/whySchoolSmart/Carreers.jpg";
import Form from "../../components/Questions/Form";

const Careers = ()=>{

    return(
        <>
         <div
        className="heroSection"
        style={{ backgroundImage: "url(" + career + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Career Opportunity…
            </h1>
            <p className="text-center text-light pTag">
            Distributed leadership grounded in shared accountability between administrators 
and teachers towards a goal of instructional excellence and increased student 
achievement.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          If you are a dynamic, personable, with a 
talent for tech and a team player?
          </h1>
          <h3 className="mt-3 bottom-text">
          Apply Now…
          </h3>
        </div>
      </div>
     <Form/>
        </>
    )
}

export default Careers