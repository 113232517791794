import React from 'react';
import BlueBand from './BlueBand/BlueBand';
import Counter from './Counter/Counter';
import FourthSection from './FourthSection/FourthSection';
import HeroSection from './Hero/HeroSection.js';
import TalkToExpert from './TalkToExpert/TalkToExpert';
import ThirdSection from './ThirdSection/ThirdSection';
import VideoSection from './VideoSection/VideoSection';
import SocialMedia from './SocialMedia/SocialMedia';
import Affiliates from './Affiliates/Affiliates';
import Footer from './Footer/Footer';
import TextSection from './TextSection/TextSection';
import TabsSection from './TabsSection/TabsSection';

function Home() {

    return (
        <div className="">
           
            <HeroSection />
            <VideoSection />
            <Counter />
            <TextSection />
            <TabsSection />
            <SocialMedia />
            
           
            {/* <BlueBand /> */}

            {/* <ThirdSection /> */}

            {/* <FourthSection /> */}

            {/* <TalkToExpert /> */}
        </div>
    )
}
export default Home;