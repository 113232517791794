import { Link } from "react-router-dom";
import accerditations from "../../Assets/whySchoolSmart/Accerditations.jpg";
import Unaswered from "../../components/Questions/Unanswered";
const Accerdiations = ()=>{

    return(
        <>
         <div
        className="heroSection"
        style={{ backgroundImage: "url(" + accerditations + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Cloud-based solution for Groups of Schools…
            </h1>
            <p className="text-center text-light pTag">
            Powering your group with an integrated school management and student information system
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Integrated software to grow with your school and enhance the experience for staff, students and parents
          </h1>
          <p className="mt-3 bottom-text">
          Many schools worldwide trust our system to support the future of education. We are one of the leading completely cloud-based information system providers.
          </p>
        </div>
      </div>
      <Unaswered/>
        </>
    )
}

export default Accerdiations