import React from 'react';
import "./ThirdSection.scss";

function ThirdSection() {
    return (
        <div className="thirdSection">
            <div className="row justify-content-center">
                <div className="col-md-3">

                </div>
                <div className="col-md-6">
                    <h5>Work smarter with Salesforce and Google.</h5>
                    <label>Salesforce and Google unite to deliver higher productivity and more connected customer experiences.</label>
                    <br />
                    <br />
                    <a href="#">LEARN MORE ABOUT THE GOOGLE AND SALESFORCE PARTNERSHIP</a>
                </div>
            </div>

        </div>
    )
}

export default ThirdSection;