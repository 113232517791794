import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import axios  from "axios";
import "./ScheduleADemo.scss";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

const ScheduleADemo = () => {
  const [openSuccessModal, setopenSuccessModal] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [studnetSmart, setstudnetSmart] = useState("No");
  const [hrSmart, sethrSmart] = useState("No");
  const [academicSmart, setacademicSmart] = useState("No");
  const [communicationSmart, setcommunicationSmart] = useState("No");
  const [pastoralSmart, setpastoralSmart] = useState("No");
  const [timtatableSmart, settimtatableSmart] = useState("No");
  const [librarySmart, setlibrarySmart] = useState("No");
  const [reportSmart, setreportSmart] = useState("No");
  const [financeSmart, setfinanceSmart] = useState("No");
  const [administrationSmart, setadministrationSmart] = useState("No");
  const [academic, setacademic] = useState("No");
  const [communication, setcommunication] = useState("No");
  const [administration, setadministration] = useState("No");
  const [it, setit] = useState("No");
  const [admission, setadmission] = useState("No");
  const [pastoral, setpastoral] = useState("No");
  const [finance, setfinance] = useState("No");
  const [contact, setcontact] = useState("");
  const [checked, setChecked] = useState({
    student: false,
    academic: false,
    hrSmart: false,
    communication: false,
    pastoral: false,
    library: false,
    timetable: false,
    administration: false,
    lms: false,
    reporting: false,
    finance: false
  });
  const [user, setUser] = useState({
    fName: "",
    lName: "",
    sName: "",
    country: "",
    email: "",
    pNumber: contact,
    jTitle: "",
    nStudent: "",
    cSystem: "",
    tSchool: "",
    aboutUs: "",
    useSchoolSmart: "",
  });

  const {
    fName,
    lName,
    email,
    sName,
    country,
    pNumber,
    jTitle,
    nStudent,
    cSystem,
    tSchool,
    aboutUs,
    useSchoolSmart,
  } = user;

  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };


  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    var body = {
      email: user.email,
      firstName: user.fName,
      lastName: user.lName,
      school: user.tSchool,
      country: user.country,
      phone: user.pNumber,
      job: user.jTitle,
      nStudent: user.nStudent,
      mis: user.cSystem,
      ss: user.useSchoolSmart,
      about: user.aboutUs,
      tSchool: user.tSchool,
      studnetSmart,
      hrSmart,
      academicSmart,
      communicationSmart,
      pastoralSmart,
      timetableSmart: timtatableSmart,
      librarySmart,
      reportSmart,
      financeSmart,
      administrationSmart,
      academic,
      communication,
      administration,
      it,
      admission,
      pastoral,
      finance
    };
    console.log("Data", body);
    await axios.post("https://embassyportal.schoolsmart.org.uk/api/ScheduleDemo", body)
      .then(function (response) {
        debugger;
        // alert("Your Request Submit Successfully");
        setopenSuccessModal(true);
      })
      .catch(function (error) {
        alert("Error");
        console.log(error)
  
      });
  };

  const selectAllFunc = (check) => {
    debugger;
    setCheckedAll(check ? "Yes" : "No");
    setstudnetSmart("Yes");
    sethrSmart("Yes");
    setacademicSmart("Yes");
    setcommunicationSmart("Yes");
    setpastoralSmart("Yes");
    settimtatableSmart("Yes");
    setlibrarySmart("Yes");
    setadministrationSmart("Yes");
    setreportSmart("Yes");
    setfinanceSmart("Yes");
  }

  return (
    <div className="container">
      <Modal
        isOpen={openSuccessModal}
        centered
        fullscreen="xl"
        size="lg"
        contentClassName="successModal"
      // toggle={function noRefCheck() { }}
      >

        <ModalBody>
          {/* <div className="text-center">
            <i className="fas fa-check-circle fa-3x text-success"></i> <label>Success</label>
            <h3 className="mt-5">Your request has been submitted successfully</h3>
          </div> */}
          <div className="row p-5 justify-content-center">
            <div className="col-md-2">
              <i className="fas fa-check-circle fa-5x text-success text-right"></i>
            </div>
            <div className="col-md-6 text-left">
              <h3 className="">Your request has been submitted successfully</h3>
            </div>
            <div className="col-md-10 mt-5">
              <Link to="/">
                <button className="btn btn-block btn-lg btn-success w-100">Continue</button>
              </Link>
            </div>
          </div>
        </ModalBody>

      </Modal>
      <div className="row mt-2">
        <div className="section-signUp1">
          {/* <h2 className="mt-3" style={{ fontFamily: "sans-serif" }}>
            Sign-Up to Watch our
          </h2> */}
          <h2 className="mt-2 font-heading" >Schedule a demo</h2>
          <p className="mt-2 font">
            SchoolSmart is to improve the efficiency of your school with a
            feature-rich, web-based school management solution. Fill out the form
            to schedule a live demo customised for your specific school needs.
          </p>
        </div>
      </div>

      <form onSubmit={(e) => onSubmit(e)}>
        <div className="row mt-3">
          <div className="form-group col-md-6">
            <label for="fName" className="label-label">
              First Name
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="fName"
              name="fName"
              value={fName}
              onChange={(e) => onInputChange(e)}
              required="true"
            />
            <label for="sName" className="label-label">
              School Name
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="sName"
              name="sName"
              value={sName}
              onChange={(e) => onInputChange(e)}
              required="true"
            />
            <label for="email" className="label-label">
              Email
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              value={email}
              onChange={(e) => onInputChange(e)}
              required="true"
            />
            <label for="jTitle" className="label-label">
              Job Tilte
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="jTitle"
              name="jTitle"
              value={jTitle}
              onChange={(e) => onInputChange(e)}
              required="true"
            />

            <label for="cSystem" className="label-label">
              Current MIS/SIS system used at your school?{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="cSystem"
              name="cSystem"
              value={cSystem}
              onChange={(e) => onInputChange(e)}
              required="true"
            />
            <label for="lName" className="label-label">
              How did you hear about us?
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="aboutUs"
              name="aboutUs"
              value={aboutUs}
              onChange={(e) => onInputChange(e)}
              required="true"
            />
            <br />
            <p className="label-label">Product Interested in: <span style={{ color: "red" }}> * </span> </p>
            <input
              type="checkbox"
              onChange={(e) => selectAllFunc(e.target.checked)}
              checked={checkedAll === "Yes" ? true : false}
            /> <label className="label-label">Select All</label>
            <br />

            <input
              type="checkbox"
              onChange={(e) => setstudnetSmart(e.target.checked === true ? "Yes" : "No")}
              checked={studnetSmart === "Yes" ? true : false}
            /> <label className="label-label">Student Smart</label>
            <br />
            <input
              type="checkbox"
              onChange={(e) => sethrSmart(e.target.checked === true ? "Yes" : "No")}
              checked={hrSmart === "Yes" ? true : false}
            /> <label className="label-label">HR Smart</label>

            <br />

            <input
              type="checkbox"
              onChange={(e) => setacademicSmart(e.target.checked === true ? "Yes" : "No")}
              checked={academicSmart === "Yes" ? true : false}
            /> <label className="label-label">Academic Smart</label>
            <br />

            <input
              type="checkbox"
              onChange={(e) => setcommunicationSmart(e.target.checked === true ? "Yes" : "No")}
              checked={communicationSmart === "Yes" ? true : false}
            /> <label className="label-label">Communication Smart</label>
            <br />
            <input
              type="checkbox"
              onChange={(e) => setpastoralSmart(e.target.checked === true ? "Yes" : "No")}
              checked={pastoralSmart === "Yes" ? true : false}
            /> <label className="label-label">Pastoral Smart</label>
            <br />

            <input
              type="checkbox"
              onChange={(e) => settimtatableSmart(e.target.checked === true ? "Yes" : "No")}
              checked={timtatableSmart === "Yes" ? true : false}
            /> <label className="label-label">Timetable Smart</label>
            <br />

            <input
              type="checkbox"
              onChange={(e) => setlibrarySmart(e.target.checked === true ? "Yes" : "No")}
              checked={librarySmart === "Yes" ? true : false}
            /> <label className="label-label">Library Smart</label>
            <br />

            <input
              type="checkbox"
              onChange={(e) => setadministrationSmart(e.target.checked === true ? "Yes" : "No")}
              checked={administrationSmart === "Yes" ? true : false}
            /> <label className="label-label">Administration Smart</label>
            <br />

            <input
              type="checkbox"
              onChange={(e) => setreportSmart(e.target.checked === true ? "Yes" : "No")}
              checked={reportSmart === "Yes" ? true : false}
            /> <label className="label-label">Reporting Smart</label>
            <br />

            <input
              type="checkbox"
              onChange={(e) => setfinanceSmart(e.target.checked === true ? "Yes" : "No")}
              checked={financeSmart === "Yes" ? true : false}
            /> <label className="label-label">Finance Smart</label>
          </div>

          <div className="col-md-6">
            <label for="lName" className="label-label">
              Last Name
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="lName"
              name="lName"
              value={lName}
              onChange={(e) => onInputChange(e)}
              required="true"
            />
            <label for="country" className="label-label">
              Country
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              name="country"
              value={country}
              onChange={(e) => onInputChange(e)}
              required="true"
            />
            <label for="country" className="label-label">
              Phone Number
              <span style={{ color: "red" }}>*</span>
            </label><br/>
                           <PhoneInput
                  className=""
                  international
                  defaultCountry="PK"
                  value={contact}
                  onChange={setcontact} />
            {/* <input
              type="text"
              className="form-control"
              id="pNumber"
              name="pNumber"
              value={pNumber}
              onChange={(e) => onInputChange(e)}
              required="true"
            /> */}
            <label for="nStudent" className="label-label">
              Number of Students
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="nStudent"
              name="nStudent"
              value={nStudent}
              onChange={(e) => onInputChange(e)}
              required="true"
            />

            <label className="label-label" for="sWebsite">Do you currently use SchoolSmart at your School? <span style={{ color: "red" }}>*</span></label>
            <input
              type="text"
              className="form-control"
              id="useSchoolSmart"
              name="useSchoolSmart"
              value={useSchoolSmart}
              onChange={(e) => onInputChange(e)}
            />
            <label for="tSchool" className="label-label">
              Type of School
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="tSchool"
              name="tSchool"
              value={tSchool}
              onChange={(e) => onInputChange(e)}
              required="true"
            />
            <br />
            <p className="label-label">I am responsible for <span style={{ color: "red" }}> * </span> </p>
            <input
              type="checkbox"
              onChange={(e) => setacademic(e.target.checked === true ? "Yes" : "No")}
              checked={academic === "Yes" ? true : false}
            /> <label className="label-label">Academics</label>
            <br />
            <input
              type="checkbox"
              onChange={(e) => setadministration(e.target.checked === true ? "Yes" : "No")}
              checked={administration === "Yes" ? true : false}
            /> <label className="label-label">Administration</label>
            <br />
            <input
              type="checkbox"
              onChange={(e) => setadmission(e.target.checked === true ? "Yes" : "No")}
              checked={admission === "Yes" ? true : false}
            /> <label className="label-label">Admissions</label>
            <br />
            <input
              type="checkbox"
              onChange={(e) => setcommunication(e.target.checked === true ? "Yes" : "No")}
              checked={communication === "Yes" ? true : false}
            /> <label className="label-label">Communication</label>
            <br />
            <input
              type="checkbox"
              onChange={(e) => setfinance(e.target.checked === true ? "Yes" : "No")}
              checked={finance === "Yes" ? true : false}
            /> <label className="label-label">Finance</label>
            <br />
            <input
              type="checkbox"
              onChange={(e) => setpastoral(e.target.checked === true ? "Yes" : "No")}
              checked={pastoral === "Yes" ? true : false}
            /> <label className="label-label">Pastoral</label>
            <br />
            <input
              type="checkbox"
              onChange={(e) => setit(e.target.checked === true ? "Yes" : "No")}
              checked={it === "Yes" ? true : false}
            /> <label className="label-label">IT Systems</label>

          </div>
        </div>
        <div className="text-section mt-3 font">
          <p>
            SchoolSmart by LinkIT is committed to protecting and respecting your
            privacy, and we’ll only use your personal information to administer
            your account and to provide the products and services you requested
            from us. From time to time, we would like to contact you about our
            products and services, as well as other content that may be of
            interest to you. If you consent to us contacting you for this
            purpose, please tick below to say how you would like us to contact
            you:
          </p>
        </div>
        <div className="checkbox-class">
          <input className="" type="checkbox" required="true" />{" "}
          <p style={{ display: "inline-block" }} className="label-label">
            I agree to receive other communications from SchoolSmart.
          </p>
          <p className="text-section font">
            In order to provide you the content requested, we need to store and
            process your personal data. If you consent to us storing your
            personal data for this purpose, please tick the checkbox below
          </p>
        </div>
        <input type="checkbox" required="true" />{" "}
        <p style={{ display: "inline-block" }} className="label-label">
          I agree to allow SchoolSmart to store and process my personal data.
          <span style={{ color: "red" }}>*</span>
        </p>
        <br />
        <button className="btn btn-primary btn-block mb-2">Submit</button>
      </form>
    </div>
  );
};
export default ScheduleADemo;
