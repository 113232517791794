import { Link } from "react-router-dom";
import contactUs from "../../Assets/whySchoolSmart/ContactUs.jpg";
import office from "../../Assets/whySchoolSmart/office.jpg"
const ContactUs = ()=>{

    return(
        <>
         <div
        className="heroSection"
        style={{ backgroundImage: "url(" + contactUs + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Contact Us…
            </h1>
            <p className="text-center text-light pTag">
            Our tech team is available for your support round the clock.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container" >
          <br />
          <div className="row">
          <h1 className="color">
          CONTACT DETAILS
          </h1>
          <div className="col-md-6" style={{textAlign:'left'}}>
          
          <h3>HEAD OFFICE</h3>
          <p className="mt-3 bottom-text">
          Knowledge Oasis Muscat (KOM), Oman.
          </p>
          <h3 className="mt-3 bottom-text">
          Postal Address
          </h3>
          <p>Post Box No. 3107</p>
          <p>Postal Code 111</p>
          <p>Muscat – Sultanate of Oman</p>
          <br/>
          <p>+968 – 79944866</p>
          <p>+968 – 93923475 (WhatsApp)</p>
          <br/>
          <p>support@schoolsmart360.com</p>
          <p>info@schoolsmart360.com</p>
          <br/>
          <h3>CITY OFFICE OMAN</h3>
          <p>2nd Tamimah Building, Al Nahdah Road,</p>
          <p>Al Wattayah, Muscat.</p>
          <br/>
          <h3>CITY OFFICE PAKISTAN</h3>
          <p>Suite# 304, Sunway Heights,</p>
          <p>Square Commercial, Bahriya Phase 7, Islamabad</p>
          <p>+ 92 333 8910234 (Voice + WhatsApp)</p>
          <br/>
        </div>
        <div className="col-md-6">
          <div className="">
          <img src={office} height={"500px"} width={"350px"} />
        </div> 
      </div>  
      </div>
      </div>
      </div>
        </>
    )
}

export default ContactUs