import React from "react";
import "./TextSection.scss";

const TextSection = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <h4 className="mt-2">
            Discover a unique needs of your business, helping to build a
            successful learning experience!
          </h4>
          
          <p className="text mt-3">
            SchoolSmart helps many schools worldwide manage every area of school
            life, wherever they are, with a truly integrated system. Let us help
            you to be the part of your success story by providing the right
            solution.
          </p>
        </div>
      </div>
    </>
  );
};
export default TextSection;
