import React from 'react';
import "./BlueBand.scss";

function BlueBand() {
    return (
        <div className="blueBandSection">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <label>Small Business Keynote: The Path to Your Best Business</label>
                    <a href="#">WATCH THE SMALL BUSINESS KEYNOTE</a>
                </div>
            </div>

        </div>
    )
}

export default BlueBand;