import React from "react";
import { Link } from "react-router-dom";
import caseStudy from "../../Assets/services/CaseStudy.jpg";
import ServiceSection from "../../components/ServiceSection/ServiceSection";

const CaseStudy = () => {
  return (
    <>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + caseStudy + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
              A flexible system that can be tailored to the needs of any
              curriculum…
            </h1>
            <p className="text-center text-light pTag">
              Acutely aware of the importance of using an accurate, efficient
              and reliable school management system would add value.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
            Are You Leveraging the Power of the Case Study Effectively in Your
            Institute?
          </h1>
          <p className="mt-3 bottom-text">
            At SchoolSmart, we provide support to our client for the collection,
            integration, processing, maintenance and dissemination of data and
            information to support decision-making, policy-analysis and
            formulation, planning, monitoring and management at all levels of an
            education system
          </p>
        </div>
      </div>
      <ServiceSection
        s1={"Services"}
        s2={ <Link to ="/installation" style={{textDecoration:'none',color:'black'}}>Installation</Link>}
        s3={"Services"}
        s4={ <Link to ="/migration" style={{textDecoration:'none',color:'black'}}>Migration</Link>}
        s5={"Our Product"}
        s6={<Link to ="/product" style={{textDecoration:'none',color:'black'}}>Modules</Link>}
      />
    </>
  );
};

export default CaseStudy;
