import React, { useState, useEffect } from "react";
import "./ProductSection.scss";
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from "reactstrap";
import SignupVideo from "../../Assets/images/SSVideo2.mp4"
import axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


const ProductWithButton = (props) => {
  const [openModal, setopenModal] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [product, setproduct] = useState("");

  useEffect(() => {
    setproduct(localStorage.getItem("product-name"))
  }, [])
  const onSubmit = async (e) => {
    e.preventDefault();

    var body = {
      email,
      name,
      contactNo: contact,
      product
    };
    console.log("Data", body);
    await axios.post("https://embassyportal.schoolsmart.org.uk/api/ProductVideo", body)
      .then(function (response) {
        debugger;
        setopenModal(false);
        setopenSuccessModal(true);
      })
      .catch(function (error) {
        alert("Error");
        console.log(error)

      });
  };
  console.log(contact);
  return (
    <div>
      <div className="mb-4 container">
        <Modal
          isOpen={openSuccessModal}
          centered
          fullscreen="xl"
          size="xl"
          contentClassName="successModal"
        // toggle={function noRefCheck() { }}
        >

          <ModalBody>
            {/* <div className="text-center">
            <i className="fas fa-check-circle fa-3x text-success"></i> <label>Success</label>
            <h3 className="mt-5">Your request has been submitted successfully</h3>
          </div> */}
            <div className="row justify-content-center">
              {/* <div className="col-md-2">
              <i className="fas fa-check-circle fa-2x text-success text-center"></i>
            </div> */}
              <div className="col-md-8 text-center">
                <h3 className=""> <i className="fas fa-check-circle text-success text-center mx-3"></i>Sign up Successful</h3>
              </div>
              <div className="col-md-12">
                <video width="100%" height="100%" controls>
                  <source src={props.videoLink} type="video/mp4" />
                  <source src="movie.ogg" type="video/ogg" />
                    Your browser does not support the video tag.
                  </video>
              </div>
              <div className="col-md-10 mt-5">
                <Link to="/">
                  <button className="btn btn-block btn-lg btn-success w-100">Continue</button>
                </Link>
              </div>
            </div>
          </ModalBody>

        </Modal>
        <Modal
          isOpen={openModal}
          // centered
          fullscreen="md"
          size="md"
        // contentClassName="successModal"
        // toggle={function noRefCheck() { }}
        >

          <ModalBody>
            <div className="row">
              <div className="col-md-12 text-center">
                <h6>Add Information</h6>
              </div>
              <div className="col-md-12">
                <label for="fName" className="label-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="name"
                  name="name"
                  value={name}
                  placeholder="Add Name here"
                  onChange={(e) => setname(e.target.value)}
                  required="true"
                />
                <label for="fName" className="label-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="name"
                  name="name"
                  value={email}
                  placeholder="Add Email here"
                  onChange={(e) => setemail(e.target.value)}
                  required="true"
                />
                <label for="fName" className="label-label">
                  Contact No
                </label>
                {/* <input
                  type="number"
                  className="form-control form-control-sm"
                  id="name"
                  name="name"
                  value={contact}
                  onChange={(e) => setcontact(e.target.value)}
                  required="true"
                /> */}
                <PhoneInput
                  className=""
                  international
                  defaultCountry="PK"
                  value={contact}
                  onChange={setcontact} />
                <label for="fName" className="label-label">
                  Product
                </label>
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="name"
                  name="name"
                  value={product}
                  onChange={(e) => setproduct(e.target.value)}
                  required="true"
                  disabled={true}
                />
              </div>

              <div className="col-md-12 mt-5" style={{ textAlign: "right" }}>
                <button className="btn btn-sm mx-3 btn-danger" onClick={() => setopenModal(false)}>Cancel</button>
                <button className="btn btn-sm btn-success" onClick={(e) => onSubmit(e)}>Submit</button>
              </div>

            </div>
          </ModalBody>

        </Modal>
        <div className="row">
          <div
            className="col-md-3  "
            style={{
              backgroundColor: props.backgroundColor,
              borderRadius: "40px",
              textAlign: "center",
            }}
          >
            <img className="img" src={props.src} alt="" />
          </div>
          <div
            className="col-md-9 leftSpacing"
            style={{
              backgroundColor: props.backgroundColor,
              borderRadius: "40px",

            }}
          >
            <h3 className="mt-2 productSection"><b>{props.heading}<p className="specialS">S</p>MART </b></h3>
            <strong><h5 className="heading5" ><b>{props.smallHeading}</b></h5></strong>
            <p className="lowerText" >{props.text}</p>
            {/* <Link style={{display:'inline-block'}} className="font" to={props.link}><p>View</p></Link> */}
            <div className="headingSection" style={{ display: "inline-block", float: 'right' }}>
              <button className="productBtn" onClick={() => setopenModal(true)}>Watch Video</button>

              <Link to={props.link2}> <button className="demoBtn">Book a demo</button></Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
export default ProductWithButton;
