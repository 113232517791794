import React from "react";
import "./Products.scss";
import communication from "../../Assets/headers/COMMUNICATION.jpg";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import communicationScreen from "../../Assets/screens/communication-s.png";
import Feature from "../../components/Features/Feature";
const CommunicationSmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + communication + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1 className="text-center text-light head">
                GETTING IT RIGHT Why Good School Communication Matters…
              </h1>
              <p className="text-center text-light pTag">
                Giving your entire school community the right information at the
                right time.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1 className="color">
              The Power of Positive Communication in a School Setting
            </h1>
            <p className="mt-4 bottom-text">
              At the heart of any great school is the successful implementation
              of the right communication channels to inform students, parents
              and teachers. We offer several modules, Portals and Apps to
              support your schools’ communication goals...
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#fffed4"}
        src={communicationScreen}
        heading={"COMMUNICATION "}
        smallHeading={
          "An Effective tool for Improvement of School Communication Management"
        }
        text={
          "CommunicationSmart is an essential tool for; understanding roles and assignments; planning and carrying out learning activities; coordinating approaches with students; providing information to teachers on student progress and behaviors; and building a positive relationship with students, teachers and parents."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/communication-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/communication%20coordinator.29b9c90e.svg"
              // }
              icon={"icon-communication-coordinator-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"COMMUNICATION COORDINATOR"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "Manages communication between admins, manager, hods, students, parents, staffs"
                }
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/bulletin.003713a9.svg"
              // }
              icon={"icon-bulletin-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"BULLETIN"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"NEWS headlines of schools"} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/eventcalendar.635626db.svg"
              // }
              icon={"icon-clender-icon  fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"CALENDER"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"School events in calender"} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/portal%20coordinator.88c5031f.svg"
              // }
              icon={"icon-communication-coordinator-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"PORTAL COORDINATION"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Giving portal access to user"} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommunicationSmart;
