import { Link } from "react-router-dom";
import aboutUs from "../../Assets/whySchoolSmart/AboutUs.jpg";
import Question from "../../components/Questions/Question";
import RichSolution from "../../components/FeatureRichSolution/RichSolution";
import Unaswered from "../../components/Questions/Unanswered";
// import ServiceSection from "../../components/ServiceSection/ServiceSection";
const AboutUs = ()=>{

    return(
        <>
         <div
        className="heroSection"
        style={{ backgroundImage: "url(" + aboutUs + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            A Product of LinkIT where ….EDUCATION is REDEFINED. 
            </h1>
            <p className="text-center text-light pTag">
            Helping you to run your teachers to focus on what matters, and to transform parent and student engagement.
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Who We Are
          </h1>
          <p className="mt-3 bottom-text">
          “LinkIT” is privately owned a Limited Liability Company (LLC), with its headquarter in Knowledge Oasis Muscat (KOM), Oman.
          </p>
          <p>“LinkIT’s vision is to transform the efficiency of education process by 
empowering businesses and education through digital transformation.</p>
<p>LinkIT where ….EDUCATION is REDEFINED
</p>
<p>With our extensive experience in educational field we have come up with a 
series of ideas and products that redefines the traditional learning methods 
with modern and smart learning.
</p>
          <br />
          <h1 className="color">
          What We Do
          </h1>
          <p className="mt-3 bottom-text">
          Provide assessment technology, content and related services for the 
education industry.
          </p>
          <p>
          Inform and improve student achievement, increase educator productivity 
and streamline data-centric operational tasks.
          </p>
          <p>
          Assist in Purchasing of all hardware and third-party software related to our 
MIS and its integrated applications.
          </p>
          <p>
          Designing and developing all science labs and their equipment purchasing.
          </p>
          <p>
          Designing and developing all language labs and their equipment 
purchasing.
          </p>
          <br/>
        </div>
      </div>
      <Unaswered/>
        </>
    )
}

export default AboutUs