import Trainings from "../../Assets/services/Training.jpg";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import { Link } from "react-router-dom";
const Training =()=>{
    return(
        <>
               <div
        className="heroSection"
        style={{ backgroundImage: "url(" + Trainings + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Take control of your system…
            </h1>
            <p className="text-center text-light pTag">
            Learn onsite, online, in groups or one-to-one.

            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Get the most from your MIS investment…
          </h1>
          <p className="mt-3 bottom-text">
          We design your training according to your priorities, modules chosen, and 
the staff you choose for training. How many staff you enroll in training is up 
to you, but do remember that the faster your entire staff gets up to speed, 
the sooner you’ll enjoy the benefits.
          </p>
        </div>
      </div>
      <br></br>
      <ServiceSection
        s1={"Services"}
        s2={<Link to ="/installation" style={{textDecoration:'none',color:'black'}}>Installation</Link>}
        s3={"Services"}
        s4={<Link to ="/integration" style={{textDecoration:'none',color:'black'}}>Integration</Link>}
        s5={"Our Product"}
        s6={<Link to ="/product" style={{textDecoration:'none',color:'black'}}>Modules</Link>}
      />
        </>
    )
    }
    
    export default Training;
    