import React, { useState } from 'react';
import "./tabsSection.scss";
import {Link} from 'react-router-dom'
import groupSchool from '../../Assets/tabsSection/Group-of-Schools.jpg'
import hybridSchool from '../../Assets/tabsSection/Hybrid-School.jpg'
import multibranchedSchool from '../../Assets/tabsSection/Multi-Branched-School.jpg'
import oneBranchedSchool from '../../Assets/tabsSection/One-Branched-School.jpg'

const TabsSection = () => {
    const [activeTab, setactiveTab] = useState(1);
    return (<div>
        <div className="container">
            <div className="tabsSection">
                <div className="row">
                    <div className="col-md-12">
                        <div className="navSection">
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn oneBranchedBtn" onClick={() => setactiveTab(1)}>One-Branched Schools {activeTab === 1 ? <div className="active1"> </div> : null}</button>
                                <button type="button" className="btn multiBranchBtn" onClick={() => setactiveTab(2)}>Multi-Branched Schools {activeTab === 2 ? <div className="active2"> </div> : null}</button>
                                <button type="button" className="btn groupBranchedBtn" onClick={() => setactiveTab(3)}>Group of Schools {activeTab === 3 ? <div className="active3"> </div> : null}</button>
                                <button type="button" className="btn hybridBranchedBtn" onClick={() => setactiveTab(4)}>Hybrid Schools {activeTab === 4 ? <div className="active4"> </div> : null}</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        {activeTab === 1 ?

                            <div className="tabContent" style={{ backgroundImage: `url(${oneBranchedSchool})` }}>
                                <h4>Single Branched Schools</h4>
                                <br />
                                <p>We understand that every Independent School needs a School Management System that makes life easier for staff, so you can deliver an outstanding education to your students</p>

                                <p>SchoolSmart achieves this by delivering an intuitive and easy-to-use MIS that can be accessed
                                from anywhere, with multiple third-party integrations of online resources to allow you to build an education system that works for your school.</p>

                                <p>We remove the stress that comes with systems that don’t work, by delivering something that does.</p>

                                <p>It’s a complete educational system, where education is redefined!</p>
                                <Link to="/oneBranchSchool">    <button className="moreInfo">More Info</button></Link>
                            </div>

                            : activeTab === 2 ?

                                <div className="tabContent" style={{ backgroundImage: `url(${multibranchedSchool})` }}>
                                    <h4>Multiple Branched Schools</h4>

                                    <p>We believe an effective school management system can play a key role in the success of a big multi-campus School.</p>

                                    <p>SchoolSmart provides a modular system that grows as your school evolves, allowing you to
                                    build a system for your exact needs: from student enrolment to end-of-year exams, timetabling,
                                    HR, academic reporting, student wellbeing and inter-campus reporting.</p>

                                    <p>Our fully integrated administration solution with multi-tasking support removes the pain of admin staff multiple reporting needs.</p>
                                    <Link to="/mbSchool">    <button className="moreInfo">More Info</button></Link>
                                </div>
                                : activeTab === 3 ?

                                    <div className="tabContent" style={{ backgroundImage: `url(${groupSchool})` }}>
                                        <h4>Group of Schools</h4>

                                        <p>We believe in streamlining the operations of schools, simplifying processes and eliminating double working</p>

                                        <p>SchoolSmart enables school groups to manage all academic, wellbeing, admissions and administrative information within a centralised location using a single MIS solution offering one interface in a single database. </p>

                                        <p>SchoolSmart is simple to implement across multiple locations and can grow alongside your schools to maintain uniform standard.</p>
                                        <Link to="/gpSchool">    <button className="moreInfo">More Info</button></Link>
                                    </div>
                                    : activeTab === 4 ?
                                
                                        <div className="tabContent" style={{ backgroundImage: `url(${hybridSchool})`}}>
                                   
                                            <h4>Hybrid Schools</h4>

                                            <p>We understand that for Hybrid Schools to deliver an excellent education, they need a digital infrastructure to match.</p>

                                            <p>SchoolSmart is a 100% web-based MIS that can be accessed from anywhere, with multiple third-party integrations to online learning platforms and resources</p>

                                            <p>Our suite of portals and mobile apps enable you to communicate effectively throughout the school community</p>
                                            <Link to="/HybridSchool">    <button className="moreInfo">More Info</button></Link>
                                        </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default TabsSection;