import React, { useEffect, useState } from 'react'
import "./counter.scss";
// import step1Image from './../../Assets/images/sf2.png';
import CountUp from "react-countup"

function Counter() {
    const [startCount, setstartCount] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setstartCount(true);
            } else {
                setstartCount(false);
            }
        });
    }, [])
    return (
        <div className="counterSection">
            <div className="container">
                {startCount ?
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="yearDiv">
                                <label><CountUp start={1} end={5} duration={3} delay={1}
                                /> +</label>
                                <h6>YEARS</h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="ViewsDiv">
                                <label><CountUp start={1} end={2} duration={3} delay={1}
                                />M +</label>
                                <h6>VIEWS</h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="hoursDiv">
                                <label><CountUp start={1} end={25} duration={3} delay={1}
                                />K +</label>
                                <h6>HOURS</h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="subjectDiv">
                                <label><CountUp start={1} end={100} duration={3} delay={1}
                                />+</label>
                                <h6>SUBJECTS</h6>
                            </div>
                        </div>
                    </div>
                    :
                    null}
            </div>
        </div>
    )
}
export default Counter;