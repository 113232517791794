import React from "react";
import "./Products.scss";
import pastoral from "../../Assets/headers/PASTORAL.jpg";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import pastoralScreen from "../../Assets/screens/pastoral-s.png";
import Feature from "../../components/Features/Feature";
const PastoralSmart = (props) => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + pastoral + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1   className="text-center text-light head">
                A web-based tool to support your School to ensure the physical
                and emotional welfare of pupils…
              </h1>
              <p className="text-center text-light pTag" >
                Wellbeing care is the essential foundation upon which learning
                can take place.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1  className="color">The Importance of Wellbeing Initiatives</h1>
            <p className="mt-4 bottom-text">
              By putting wellbeing initiatives in place, schools can create a
              better environment for bridging the gap between students and
              teachers, increasing communication and ensuring better
              development.
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#f2f2f2"}
        src={pastoralScreen}
        heading={"Wellbeing "}
        smallHeading={
          "A dedicated Wellbeing Care Module to track the behaviour of students including academic achievement, attendance, and detention."
        }
        text={
          "We have carefully developed our wellbeing care module to ensure that you can support your staff and student wellbeing, and that it continues to evolve to suit the requirements of our community.It is integrated throughout the organisation of your school, our modules support schools to meet the wellbeing needs of students from activities, disciplines to reward and conduct."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/pastoral-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/medical%20centre.553176f7.svg"
              // }
              icon={"icon-sen-manager-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>

         
                <Feature heading={"MEDICAL CENTER"} 
                  
                
                />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Handle all school medicals."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/activities.2def2846.svg"
              // }
              icon={"icon-report-manager-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ACTIVITIES"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "All school activities program managed under this module."
                }
              />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/exclusion.0b427cab.svg"
              // }
              icon={"icon-exclusion-icon  fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"EXCLUSION"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Manage Exclusison"} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/sen%20manager.511086fe.svg"
              // }
              icon={"icon-sen-manager-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"SEN MANAGER"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Education for special students."} />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/wellbeing%20manager.bc05b47f.svg"
              // }
              icon={"icon-report-manager-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"REPORT MANAGER"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Manage wellbeing records of studnets."} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/student%20profile.47a81ceb.svg"
              // }
              icon={"icon-student-profile-icon fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STUDENT PROFILE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={"All academic histroies of students in profile module."}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PastoralSmart;
