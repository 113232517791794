const Unaswered = (props) => {
    return (
      <>
        <div className="container mb-2">
          <div className="mt-5 align">
            <h2 className="interested-text  text-center mb-2">
              Have unanswered questions?
            </h2>
            <p className="mt-2 mb-2 text-center" style={{ fontSize: "1.25rem" }}>
              Sometimes it’s easier to chat – we’re always happy to talk in detail
            </p>
            <button className="btn btn-primary mt-2 mb-4">Give us a call</button>
          </div>
        </div>
      </>
    );
  };
  export default Unaswered;
  