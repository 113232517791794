import hybridSchool from "../../Assets/solution/HybridSchools.jpg";
import Question from "../../components/Questions/Question";
import RichSolution from "../../components/FeatureRichSolution/RichSolution";

const HybridSchool = ()=>{

    return(
        <>
           <div
        className="heroSection"
        style={{ backgroundImage: "url(" + hybridSchool + ")" }}
      >
        <div className="container">
          <div className="row">
            <h1 className="text-center text-light head">
            Cloud-based solution for Hybrid Schools…
            </h1>
            <p className="text-center text-light pTag">
            Powering your hybrid school with an integrated school management and student information system
            </p>
          </div>
        </div>
        <br />
      </div>
      <div className="textSection">
        <div className="container">
          <br />
          <h1 className="color">
          Integrated software to grow with your school and enhance the experience for staff, students and parents
          </h1>
          <p className="mt-3 bottom-text">
          Many schools worldwide trust our system to support the future of education. We are one of the leading completely cloud-based information system providers.
          </p>
        </div>
      </div>
      <RichSolution />
      <br></br>
      <Question bannerText={"Enhance communication between schools and their students and parents with our online portals"}/>
        </>
    )
}

export default HybridSchool