import React from "react";
import "./Products.scss";
import hr from "../../Assets/headers/HRSMART.jpg";
import { Link } from "react-router-dom";
import ProductWithButton from "../../components/ProductSesction/ProductWithButton";
import hrScreen from "../../Assets/screens/HR-s.png";
import Feature from "../../components/Features/Feature";
const HRSmart = () => {
  return (
    <div>
      <div
        className="heroSection"
        style={{ backgroundImage: "url(" + hr + ")" }}
      >
        <div
          style={{
            backgroundColor: "#000",
            display: "inline-block",
            width: "100%",
            opacity: "0.7",
          }}
        >
          <div className="container">
            <div className="row">
              <h1 className="text-center text-light head">
                Incredible, completely cloud-based HR program for schools
              </h1>
              <p className="text-center text-light pTag" >
                It gives your school's HR faculty the opportunity to focus on
                more important things than documenting and logging structures –
                yet the chance to acquire all-encompassing or independently
                based bits of knowledge into everything staff-related.
              </p>
              <p className="hp text-light">
                <Link className="text-light" to="/">
                  Home
                </Link>{" "}
                |{" "}
                <Link className="text-light" to="/product">
                  Products
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="textSection">
          <div className="container">
            <br />
            <h1 className="color">
              A thorough and adaptable self-administration HR apparatus that and
              instructors the same to zero in on the main thing
            </h1>
            <p className="mt-4 bottom-text">
              HR Smart intended to computerize tedious manual and dull HR
              managerial undertakings, give HR staff improved bits of knowledge
              and, with a completely marked, customisable worker
              self-administration dashboard
            </p>
          </div>
        </div>
      </div>
      <br />
      <ProductWithButton
        backgroundColor={"#deebf7"}
        src={hrScreen}
        heading={"HR "}
        smallHeading={"Alleviate the HR burden through self administration"}
        text={
          "By and large, staff the executives has been an activity in passing reports and structures to and fro. Information put away in divergent areas, slow interchanges among HR and educators and other staff individuals and a ton of tedious administrator, pursuing and hanging tight – for everybody concerned. HRSmart's cloud-based self-administration work changes the progression of data. It gives instructors  and staff liability regarding their own data, a prompt, initially perspective on everything from occasions booked and time off wiped out through to contract terms and impending preparing openings."
        }
        link1={"/"}
        link2={"/SchedulSingleDemo"}
        videoLink={"https://schoolsmart360.com/videos/hr-smart.mp4"}
      />
      <br />
      <div className="container">
        <h2 className="text-center font-feature">Features overview</h2>

        <div className="row mt-5">
          <div className="col-md-2">
            <Feature
             icon={"icon-staff-registration-frm fa-6x"}
             inn1={"path1"}
             inn2={"path2"}
             inn3={"path3"}
             inn4={"path4"}
             inn5={"path5"}
             inn6={"path6"}
             inn7={"path7"}
              // img={
              //   "http://schoolsmart.org.uk/static/media/staffdoc.63944686.svg"
              // }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STAFF REGISTRATION FORM"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "A complete cloud-based registration process where you can attached all relevant documents with every  students online."
                }
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              icon={"icon-deparment-and-training-module fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
              // img={
              //   "http://schoolsmart.org.uk/static/media/hr%20dev.%20nd%20training.98c6f204.svg"
              // }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"DEVELOPMENT & TRAINING"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "A tool assist HR Manager to organize training and development programs for all staff members and send push notifications. "
                }
              />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
                icon={"icon-staff-registration-frm fa-6x"}
                inn1={"path1"}
                inn2={"path2"}
                inn3={"path3"}
                inn4={"path4"}
                inn5={"path5"}
                inn6={"path6"}
                inn7={"path7"}
              // img={
              //   "http://schoolsmart.org.uk/static/media/front%20desk%20(HR).19ef0648.svg"
              // }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"ONLINE REGISTRATION FORM"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={"Staff can enquire and register themselves online."}
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
                icon={"icon-deparment-module fa-6x"}
                inn1={"path1"}
                inn2={"path2"}
                inn3={"path3"}
                inn4={"path4"}
                inn5={"path5"}
                inn6={"path6"}
                inn7={"path7"}
               
              // img={
              //   "http://schoolsmart.org.uk/static/media/department.2aaa9c91.svg"
              // }
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"DEPARTMENT MODULE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "Segregation of staff into various categories and create different reports."
                }
              />
            </p>
          </div>

          <div className="col-md-2">
            <Feature
              // img={"http://schoolsmart.org.uk/static/media/post.a350de1f.svg"}
              icon={"icon-post-module fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
             
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"POST MODULE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature text={"Create openings and posting on portal. "} />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/payroll.90c56c21.svg"
              // }
              icon={"icon-payroll fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"PAYROLL"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "A fully integrated payroll tool with staff evaluation forms and appraisal criteria, payroll slips and various  reports."
                }
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/student%20coordinator.ccc01bde.svg"
              // }
              icon={"icon-student-coordinator fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STUDENT COORDINATOR"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={"Managing student class, sections and transfers."}
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/staff%20coordinator.08ca184c.svg"
              // }
              icon={"icon-staff-coordinator fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STAFF COORDINATOR"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "Assign roles like class, subject, and class-teacher and home-room teacher to staff, managing their reports."
                }
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/staff%20coordinator.08ca184c.svg"
              // }
              icon={"icon-staff-leave fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"STAFF LEAVE"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={"Managing student class, sections and transfers."}
              />
            </p>
          </div>
          <div className="col-md-2">
            <Feature
              // img={
              //   "http://schoolsmart.org.uk/static/media/biometric%20main%20icon.7d742bea.svg"
              // }
              icon={"icon-hr-biomatric fa-6x"}
              inn1={"path1"}
              inn2={"path2"}
              inn3={"path3"}
              inn4={"path4"}
              inn5={"path5"}
              inn6={"path6"}
              inn7={"path7"}
            />
          </div>
          <div className="col-md-4">
            <span>
              <h4 style={{ display: "inline-block" }}>
                <Feature heading={"BIOMETRIC"} />
              </h4>
            </span>
            <p>
              {" "}
              <Feature
                text={
                  "A fully integrated desktop application managing biometric attendance and  generating reports."
                }
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HRSmart;
