import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Products from "./Pages/Products/Product";
import StudentSmart from "./Pages/Products/StudentSmart";
import HRSmart from "./Pages/Products/HRSmart";
import ScheduleADemo from "./Pages/SchedulaADemo/ScheduleADemo";
import SignupForm from "./Pages/SignUp/SignupForm";
import Header from "./components/Header/Header";
import Affiliates from "./components/Affiliates/Affiliates";
import Footer from "./components/Footer/Footer";
import AcademicSmart from "./Pages/Products/AcademicSmart";
import AdministrationSmart from "./Pages/Products/AdministrationSmart";
import CommunicationSmart from "./Pages/Products/CommunicationSmart";
import FinanceSmart from "./Pages/Products/FinanceSmart";
import LibrarySmart from "./Pages/Products/LibrarySmart";
import PastoralSmart from "./Pages/Products/PastoralSmart";
import TimetableSmart from "./Pages/Products/TimetableSmart";
import ReportSmart from "./Pages/Products/ReportSmart";
import Whatsapp from "./components/Whatsapp/Whatsapp";
import SchedulSingleDemo from './Pages/SchedulaADemo/ScheduleSingleDemo';
import CaseStudy from './Pages/Services/CaseStudy';
import Consultancy from './Pages/Services/Consultancy';
import Migration from './Pages/Services/Migration';
import Integration from './Pages/Services/Intgeration';
import Installation from './Pages/Services/Installation';
import Training from './Pages/Services/Training';
import GPSchool from "./Pages/Solution/GPSchool";
import HybridSchool from "./Pages/Solution/HybridSchool";
import MBSchool from "./Pages/Solution/MBSchool";
import OneBracnhSchool from "./Pages/Solution/OneBranchedSchool";
import ScrollToTop from "./components/ScrollToTop";
import AboutUs from "./Pages/WhySchoolSmart/AboutUs";
import ContactUs from "./Pages/WhySchoolSmart/ContactUs";
import Accerditations from "./Pages/WhySchoolSmart/Accerditations";
import Press from "./Pages/WhySchoolSmart/Press";
import Event from "./Pages/WhySchoolSmart/Event";
import Careers from "./Pages/WhySchoolSmart/Careers";
import TechnicalSupport from "./Pages/Support/TechnicalSupport";
import  HelpGuide from "./Pages/Support/HelpGuide";
import TrainingWebinar from "./Pages/Support/TrainingWebinar";
import TrainingVideos from "./Pages/Support/TrainingVideos";
import StudentParent from "./Pages/Support/StudentParent";
import AffiliatesPartner from "./Pages/Support/AffiliatesPartner";
import Chatbot from "./Pages/Support/Chatbot";
import RegProcess from "./Pages/WhySchoolSmart/RegProcess";
import Collaboration from "./Pages/WhySchoolSmart/Collaboration";

function App() {
  return (
    <Router>
      <div className="s">
        <ScrollToTop>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/product" component={Products} />
            <Route exact path="/signup" component={SignupForm} />
            <Route exact path="/schedule" component={ScheduleADemo} />
            <Route exact path="/studentSmart" component={StudentSmart} />
            <Route exact path="/hrSmart" component={HRSmart} />
            <Route exact path="/academicSmart" component={AcademicSmart} />
            <Route exact path="/administrationSmart" component={AdministrationSmart} />
            <Route exact path="/communicationSmart" component={CommunicationSmart} />
            <Route exact path="/financeSmart" component={FinanceSmart} />
            <Route exact path="/librarySmart" component={LibrarySmart} />
            <Route exact path="/pastoralSmart" component={PastoralSmart} />
            <Route exact path="/timetableSmart" component={TimetableSmart} />
            <Route exact path="/reportSmart" component={ReportSmart} />
            <Route exact path="/whatsapp" component={Whatsapp} />
            <Route exact path="/SchedulSingleDemo/" component={SchedulSingleDemo} />
            <Route exact path="/consultancy" component={Consultancy} />
            <Route exact path="/caseStudy/" component={CaseStudy} />
            <Route exact path="/installation/" component={Installation} />
            <Route exact path="/migration/" component={Migration} />
            <Route exact path="/integration/" component={Integration} />
            <Route exact path="/training/" component={Training} />
            <Route exact path="/gpSchool/" component={GPSchool} />
            <Route exact path="/hybridSchool/" component={HybridSchool} />
            <Route exact path="/mbSchool/" component={MBSchool} />
            <Route exact path="/oneBranchSchool/" component={OneBracnhSchool} />
            <Route exact path="/aboutUs/" component={AboutUs} />
            <Route exact path="/contactUs/" component={ContactUs} />
            <Route exact path="/accerditations/" component={Accerditations} />
            <Route exact path="/event/" component={Event} />
            <Route exact path="/press/" component={Press} />
            <Route exact path="/careers/" component={Careers} />
            <Route exact path="/chatbot/" component={Chatbot} />
            <Route exact path="/helpGuide/" component={HelpGuide} />
            <Route exact path="/technicalSupport/" component={TechnicalSupport} />
            <Route exact path="/trainingWebinar/" component={TrainingWebinar} />
            <Route exact path="/trainingVideos/" component={TrainingVideos} />
            <Route exact path="/studentParent/" component={StudentParent} />
            <Route exact path="/affiliatesPartner/" component={AffiliatesPartner} />
            <Route exact path="/collaboration/" component={Collaboration} />
            <Route exact path="/regProcess/" component={RegProcess} />
          </Switch>
          <Affiliates />
          <Footer />
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
