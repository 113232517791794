import React from 'react';
import "./FourthSection.scss";

function FourthSection() {
    return (
        <div className="fourthSection">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-9">
                        <h1>Transform your business with customisable tools that grow with you.</h1>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="card cardSections text-center">
                                    <div>
                                        <img width="85" height="85" src="https://www.salesforce.com/content/dam/web/global/icons/product/sales-day.svg?version=202190" alt="1st" />
                                    </div>
                                    <h3>Sales</h3>
                                    <p className="mt-4">Improve, centralise, and accelerate your sales</p>
                                    <br />
                                    <a>LEARN MORE</a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card cardSections text-center">
                                    <div>
                                        <img width="85" height="85" src="https://www.salesforce.com/content/dam/web/global/icons/product/service-day.svg?version=202190" alt="1st" />
                                    </div>
                                    <h3>Sales</h3>
                                    <p className="mt-4">Improve, centralise, and accelerate your sales</p>
                                    <br />
                                    <a>LEARN MORE</a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card cardSections text-center">
                                    <div>
                                        <img width="85" height="85" src="https://www.salesforce.com/content/dam/web/global/icons/product/marketing-day.svg?version=202190" alt="1st" />
                                    </div>
                                    <h3>Sales</h3>
                                    <p className="mt-4">Improve, centralise, and accelerate your sales</p>
                                    <br />
                                    <a>LEARN MORE</a>
                                </div>
                            </div>
                        </div>

                        <h1 className="text-center mt-3">Find, win, and keep customers.</h1>

                        <div className="row mt-5">
                            <div className="col-md-6">
                                <div>
                                    <img height="100%" width="100%" className="img-responsive" src="https://www.salesforce.com/content/dam/web/en_gb/www/images/cro/overview/ui-screen-sales.png" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <img src="https://www.salesforce.com/content/dam/web/en_gb/www/images/cro/overview/sales-cloud-logo-sml.png" />
                                    <h4 className="mt-3">Win deals and drive growth using a sales CRM built for small business.</h4>
                                    <p className="mt-4">Focus your team on selling more by automating your sales process and cutting out admin work.</p>
                                    <div>
                                        <button className="btn btn-primary btn-sm mt-2">LEARN ABOUT SALES CLOUD</button>
                                        <a className="pricing" href="">SEE PRICING</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5 flex-row-reverse">
                            <div className="col-md-6">
                                <div>
                                    <img height="100%" width="100%" className="img-responsive" src="https://www.salesforce.com/content/dam/web/en_gb/www/images/cro/overview/ui-screen-service.png" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <img src="https://www.salesforce.com/content/dam/web/en_gb/www/images/cro/overview/service-cloud-logo-sml.png" />
                                    <h4 className="mt-3">Keep customers happy with a relationship building help desk solution.</h4>
                                    <p className="mt-4">Focus your team on selling more by automating your sales process and cutting out admin work.</p>
                                    <div>
                                        <button className="btn btn-primary btn-sm mt-2">LEARN ABOUT SALES CLOUD</button>
                                        <a className="pricing" href="">SEE PRICING</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-6">
                                <div>
                                    <img height="100%" width="100%" className="img-responsive" src="https://www.salesforce.com/content/dam/web/en_gb/www/images/cro/overview/ui-screen-marketing.png" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <img src="https://www.salesforce.com/content/dam/web/en_gb/www/images/cro/overview/marketing-cloud-sml.png" />
                                    <h4 className="mt-3">Keep customers happy with a relationship building help desk solution.</h4>
                                    <p className="mt-4">Focus your team on selling more by automating your sales process and cutting out admin work.</p>
                                    <div>
                                        <button className="btn btn-primary btn-sm mt-2">LEARN ABOUT SALES CLOUD</button>
                                        <a className="pricing" href="">SEE PRICING</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: 100 }}>
                            <div className="col-md-3">
                                <img src="https://www.salesforce.com/content/dam/web/en_us/www/images/hub/service/salesforce-trailhead-badge.png" />
                            </div>

                            <div className="col-md-9">
                                <h3>Discover Trailhead and follow a path to better sales, service, and marketing.</h3>

                                <br />

                                <a href="#" className="learnAnchor">Learn for Free</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourthSection;