import React from 'react'
import "./HeroSection.scss";
import step1Image from './../../Assets/images/sf2.png';
import { Link } from 'react-router-dom';

function HeroSection() {

    return (
        <div className="heroSection" style={{ backgroundImage: 'url(' + step1Image + ')' }}>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-2">
                        <div className="sideSection">
                            <img src="https://www.salesforce.com/content/dam/web/global/svg-icons/product-smb-day.svg?version=1632847281529" />
                            <h5>Small Business Solutions</h5>
                            <button className="btn btn-primary">Watch Demo</button>

                            <ul></ul>
                        </div>
                    </div> */}
                    <div className="col-md-7 text-left mt-5">
                        <h3 className="text-light">Every area of school life, just got easier and simple to manage.</h3>
                        {/* <p>Explore a Customer Relationship Management (CRM) tailored to the unique needs of your business.</p> */}
                    </div>
                    <div className="col-md-5">

                    </div>
                    <div className="col-md-10 headingSection mt-4">
                        <div className="">

                            <Link to="/product">    <button className="productBtn">View Product</button></Link>
                            <Link to="/schedule"> <button className="demoBtn">Book a demo</button></Link>  <br />
                            <Link to="/signup">
                                <div className="watchDemoSection mt-3">
                                    <i class="far fa-play-circle fa-2x text-light demoVideo" ></i>  <p className="text-light" style={{ display: "inline-block" }}>Watch Demo</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>




            </div>
        </div>
    )
}
export default HeroSection;