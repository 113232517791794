const Question = (props) => {
    return (
      <>
        <div className="container mb-2">
          <div className="mt-5 align">
            <h2 className="interested-text  text-center mb-2">
             {props.heading}
            </h2>
            <p className="mt-2 mb-2 text-center" style={{ fontSize: "1.25rem" }}>
              {props.paragraph}
            </p>
            <button className="btn btn-primary mt-2 mb-4">{props.button}</button>
          </div>
        </div>
      </>
    );
  };
  export default Question;
  